import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'

const CorgiPage = () => {
  const pageTitle = 'LAURIE ON CORGI'

  return (
    <Layout>
      <Helmet title={pageTitle} />
      <section>
        <StaticImage
          src="../data/headers/laurieoncorgi.jpg"
          alt="Laurie on corgi"
        />
      </section>
    </Layout>
  )
}

export default CorgiPage
